<template>
    <div>
        <PollSearchCard v-for="poll in polls" :key="poll.poll_id" :poll="poll" @view-poll="viewPoll"/>
    </div>
</template>

<script>
import PollSearchCard from './PollSearchCard.vue';

export default {

    props: {
        polls: {
            type: Object,
            required: true
        }
    },

    components: {
        PollSearchCard
    },

    methods: {
        viewPoll(pollId) {
            this.$emit('go-to-poll', pollId);
        }
    }
}
</script>

<style scoped>

.sideways {
    display: flex;
    flex-direction: row;
}
.searchbox {
    margin: 10px;
    font-size: 1.5rem;
    border-radius: 5px;
    width: 50vw;
}

#buttonSearch {
    height: 33px;
    margin-top: 10px;
}
</style>