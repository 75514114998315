<template>
    <div class="master-container">
        <ReportedPollCommentsSideBar @update-limit="updateLimit" @update-offset="updateOffset"/>
        <div class="display-container">
            <router-view :reported-poll-comments="reportedPollComments" @go-to-report="goToReport"></router-view>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

import ReportedPollCommentsSideBar from '../components/reported_poll_comments/ReportedPollCommentsSideBar.vue';

export default {
    components: {
        ReportedPollCommentsSideBar,
    },

    data() {
        return {
            reportedPollComments: [],
            ascending: false,
            limit: null,
            offset: null
        };
    },

    mounted() {
        this.fetchReportedComments();
    },

    methods: {
        toggleAscending() {
            this.ascending = !this.ascending;
        },
        updateLimit(newLimit) {
            this.limit = newLimit;
        },
        updateOffset(newOffset) {
            this.offset = newOffset;
        },
        fetchReportedComments() {
            axios.post("/php/reported_poll_comments_get_limit_offset.php", {
                ascending: this.ascending,
                limit: this.limit,
                offset: this.offset
            }).then(response => {
                this.reportedPollComments = response.data;
            }).catch(error => {
                console.error("An error occurred while fetching data: ", error);
            });
        },
        
        goToReport(reportId) {
            this.$router.push({ name: 'ReportedPollCommentPage', params: { reportId: reportId } });
        }   
    },

    watch: {
        ascending: "fetchReportedComments",
        limit: "fetchReportedComments",
        offset: "fetchReportedComments"
    }
}
</script>

<style scoped>
.master-container {
    display: flex;
    flex-direction: row;
}
</style>