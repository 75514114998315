<template>
  <TopBar/>
  <TabBar v-if="isLoggedInAdmin"/>
  <div class="login-prompt-container" v-else>
    <AdminLoginPrompt/>
  </div>
</template>

<script>
import axios from 'axios';
import TopBar from './components/general/TopBar.vue';
import TabBar from './components/general/TabBar.vue';
import AdminLoginPrompt from './components/general/AdminLoginPrompt.vue'

export default {
  name: 'App',
  components: {
    TopBar,
    TabBar,
    AdminLoginPrompt
  },

  data() {
    return {
      isLoggedInAdmin: false
    };
  },

  mounted() {
    axios.get("/php/admin_session_check.php", {
      withCredentials: true
    })
    .then(response => {
      this.isLoggedInAdmin = response.data.authenticated;
    });
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

body, html {
  background-color: #8791BE;
  color: #C8CDE1;
  margin: 0px;
  padding: 0px;
}

.login-prompt-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height:100vh;
}

.standard-button {
  background: #C8CDE1;
  border: 2px solid #C8CDE1;
  color: #000000;
  border-radius: 5px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
}

.standard-button:hover, .standard-button:focus {
  background: #D8DDF1;
}
</style>
