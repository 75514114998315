<template>
    <div class="card-container" @click="emitViewPoll">
        <div class="poll-content">{{ poll.content }}</div>
    </div>
</template>

<script>
export default {

    props: {
        poll: {
            type: Object,
            required: true
        }
    },

    methods: {
        
        emitViewPoll() {
            this.$emit('view-poll', this.poll.poll_id);
        }
    }
}
</script>

<style scoped>
.card-container {
    display: flex;
    flex-direction: row;
    background-color: #C8CDE1;
    color: #000000;
    border: 2px solid #C8CDE1;
    border-radius: 10px;
    padding: 5px;
    margin:10px;
    cursor: pointer;
    width: 50vw;
}

.card-container:hover {
    border: 2px solid #FFFFFF;
}

.poll-content {
    margin-left: 20px;
    margin-right: 20px;
    text-align: left;
}
</style>