<template>
    <div class="header"><b>thoughtMetro</b> Admin Control Panel</div>
</template>

<script>

</script>

<style scoped>
.header {
    background: #C8CDE1;
    color: #8791BE;
    font-size: 2rem;
}
</style>