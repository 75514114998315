<template>
    <div class="master-container">
        <div>
            <div class="header">Reported Polls</div>
            <br>
            <div>Limit</div>
            <input class="textbox" type="text" v-model="limit">
            <div>Offset</div>
            <input class="textbox" type="text" v-model="offset">
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            limit: '',
            offset: ''
        }
    },
    watch: {
        limit: function(newLimit) {
            this.$emit('update-limit', parseInt(newLimit, 10));
        },
        offset: function(newOffset) {
            this.$emit('update-offset', parseInt(newOffset, 10));
        }
    }
}
</script>

<style scoped>
.master-container {
    margin: 0px;
    display: flex;
    flex-direction: column;
    min-width: 300px;
    height: 100vh;
    background-color: #555555;
    color: #FFFFFF;
    text-align: center;
    justify-content: center;
}

.header {
    font-size: 1.5rem;
}

.textbox {
    border-radius: 5px;
}

</style>