<template>
    <div class="master-container" v-if="reportId && report && comment">
        <div class="header">
            <b>Poll Comment Report: {{ reportId }}</b>
        </div>

        <div class="report-info">
            <br>
            <b>Report Information:</b>
            <br>
            Reason: {{ report.reason_id }}
            <br>
            Details:  "{{ report.details }}"
            <br>
            Reporting User:  {{ report.user_id }}
        </div>
        
        <div class="comment-info">
            <br>
            <b>Poll Information:</b>
            <br>
            Posting User:  {{ comment.user_id }}
            <br>
            Comment:  "{{ comment.content }}"
        </div>

        <div class="report-options">
            <br>
            <b>Report Options:</b>
            <br>
            <div class="standard-button" @click="dismissReportNoAction">No Action, Dismiss Report</div>
            <div class="standard-button" @click="dismissReportRemoveComment">Delete Comment</div>
            <div class="standard-button" @click="dismissReportRemoveCommentBanUser">Delete Comment, Ban User</div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            reportId: null,
            report: null,
            comment: null,
            pollOptions: []
        }
    },

    mounted() {
        this.reportId = this.$route.params.reportId;
        this.fetchReport();
    },

    methods: {
        async fetchReport() {
            try {
                const response = await axios.post('/php/reported_poll_comment_get.php', {
                    report_id: this.reportId
                });

                if (response.data) {
                    this.report = response.data;
                    this.fetchComment();
                }
            } catch (error) {
                console.error('There was an error fetching the report:', error);
            }
        },

        async fetchComment() {
            try {
                const response = await axios.post('/php/poll_comment_get.php', {
                    comment_id: this.report.comment_id
                });

                if (response.data) {
                    this.comment = response.data;
                }
            } catch (error) {
                console.error('There was an error fetching the comment:', error);
            }
        },

        async dismissReport() {
            try {
                const response = await axios.post('/php/reported_poll_comment_delete_and_all_related.php', {
                    comment_id: this.report.comment_id
                });
                
                if (response.data.message) {
                    console.log(response.data.message);
                } else if (response.data.error) {
                    console.error(response.data.error);
                }
            } catch (error) {
                console.error('There was an error removing the reports:', error);
            }
        },

        async banUser() {
            try {
                const response = await axios.post('/php/user_ban.php', {
                    user_id: this.comment.user_id
                });
                
                if (response.data.message) {
                    console.log(response.data.message);
                } else if (response.data.error) {
                    console.error(response.data.error);
                }
            } catch (error) {
                console.error('There was an error banning the user:', error);
            }
        },

        async removeComment() {
            try {
                const response = await axios.post('/php/poll_comment_remove.php', {
                    comment_id: this.report.comment_id
                });
                
                if (response.data.message) {
                    console.log(response.data.message);
                } else if (response.data.error) {
                    console.error(response.data.error);
                }
            } catch (error) {
                console.error('There was an error removing the comment:', error);
            }
        },

        dismissReportNoAction() {
            this.dismissReport();
            location.reload();
        },

        dismissReportRemoveComment() {
            this.removeComment();
            this.dismissReport();
            location.reload();
        },

        dismissReportRemoveCommentBanUser() {
            this.removeComment();
            this.dismissReport();
            this.banUser();
            location.reload();
        },

        emitViewReport() {
            this.$emit('view-report', this.report.report_id);
        }
    }
}
</script>

<style scoped>
.master-container {
    display: flex;
    flex-direction: column;
    margin: 20px;
    text-align: left;
}

.header {
    font-size: 1.5rem;
}

.report-info {
    font-size: 1.25rem;
}

.comment-info {
    font-size: 1.25rem;
}

.report-options {
    font-size: 1.25rem;
}

.standard-button {
    margin-top: 5px;
    margin-bottom: 5px;
}
</style>