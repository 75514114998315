<template>
    <div class="master-container">
        <PollsSideBar @update-limit="updateLimit" @update-offset="updateOffset" @update-query="updateQuery"/>
        <div class="display-container">
            <router-view :polls="polls" @go-to-poll="goToPoll"></router-view>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

import PollsSideBar from '../components/poll_management/PollsSideBar.vue';

export default {

    components: {
        PollsSideBar
    },

    data() {
        return {
            polls: [],
            loaded: null,
            limit: null,
            offset: null,
            query: null
        }
    },

    methods: {
        goToPoll(pollId) {
            this.$router.push({ name: 'PollPage', params: { pollId: pollId } });
        },

        updateLimit(newLimit) {
            this.limit = newLimit;
            this.fetchPolls();
        },

        updateOffset(newOffset) {
            this.offset = newOffset;
            this.fetchPolls();
        },

        updateQuery(newQuery) {
            this.query = newQuery;
            console.log(this.query);
            this.fetchPolls();
        },

        async fetchPolls() {
            console.log("fetchPolls");
            try {
                const response = await axios.post(`/php/polls_search.php`, { search: this.query, limit: this.limit, offset: this.offset });
                if (response.data.status === 'success') {
                    this.polls = response.data.data
                    this.loaded = true;
                } else {
                    console.error(response.data.message);
                }
            } catch (error) {
                console.error('Error fetching polls:', error);
            }
        },
    }
}
</script>

<style scoped>
.master-container {
    display: flex;
    flex-direction: row;
}
.sideways {
    display: flex;
    flex-direction: row;
}
.searchbox {
    margin: 10px;
    font-size: 1.5rem;
    border-radius: 5px;
    width: 50vw;
}

#buttonSearch {
    height: 33px;
    margin-top: 10px;
}
</style>