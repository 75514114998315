<template>
    <div class="list-container">
        <ReportedPollCommentCard v-for="report in reportedPollComments" :report="report" :key="report.report_id" @view-report="viewReport"/>
    </div>
</template>

<script>
import ReportedPollCommentCard from './ReportedPollCommentCard.vue';

export default {
    components: {
        ReportedPollCommentCard
    },

    props: ['reportedPollComments'],
    
    methods: {
        viewReport(reportId) {
            this.$emit('go-to-report', reportId);
        }
    }
}
</script>

<style scoped>
.list-container {
    display: flex;
    flex-direction: column;
}
</style>