import { createRouter, createWebHistory } from 'vue-router';

import ReportedPollsPage from '../views/ReportedPollsPage.vue';
import ReportedPollsList from "../components/reported_polls/ReportedPollsList.vue";
import ReportedPollPage from "../components/reported_polls/ReportedPollPage.vue";

import ReportedPollCommentsPage from '../views/ReportedPollCommentsPage.vue';
import ReportedPollCommentsList from "../components/reported_poll_comments/ReportedPollCommentsList.vue";
import ReportedPollCommentPage from "../components/reported_poll_comments/ReportedPollCommentPage.vue";

import PollManagementPage from '../views/PollManagementPage.vue';
import PollsList from '../components/poll_management/PollsList.vue';
import PollPage from '../components/poll_management/PollPage.vue';

const routes = [
    {
        path: '/reported_polls',
        component: ReportedPollsPage,
        children: [
            {
                path: '', // default child path
                component: ReportedPollsList
            },

            {
                path: ':reportId', 
                name: 'ReportedPollPage',
                component: ReportedPollPage
            }
        ]
    },

    {
        path: '/reported_poll_comments',
        component: ReportedPollCommentsPage,
        children: [
            {
                path: '', // default child path
                component: ReportedPollCommentsList
            },

            {
                path: ':reportId', 
                name: 'ReportedPollCommentPage',
                component: ReportedPollCommentPage
            }
        ]
    },

    {
        path: '/poll_management',
        component: PollManagementPage,
        children: [
            {
                path: '', // default child path
                component: PollsList
            },

            {
                path: ':pollId',
                name: 'PollPage',
                component: PollPage
            }
        ]
    },
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;