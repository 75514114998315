<template>
    <div style="color:#8791BE; height:2px;"></div>
    
    <div class="tabs">
        <button class="tab-button" @click="changeTab('tabPlaceholder')">Placeholder</button>
        <button class="tab-button" @click="changeTab('tabPollManagement')">Poll Management</button>
        <button class="tab-button" @click="changeTab('tabReportedPolls')">Reported Polls</button>
        <button class="tab-button" @click="changeTab('tabReportedPollComments')">Reported Poll Comments</button>
    </div>

    <div style="color:#8791BE; height:2px;"></div>

    <router-view/>
</template>


<script>
import { ref } from 'vue';
import { useRouter } from 'vue-router';

export default {
  setup() {
    const router = useRouter();
    const activeTab = ref('tabPlaceholder');

    function changeTab(tabName) {
      if(tabName === 'tabPlaceholder') {
        router.push('/placeholder');
      } else if(tabName === 'tabReportedPolls') {
        router.push('/reported_polls');
      } else if(tabName === 'tabReportedPollComments') {
        router.push('/reported_poll_comments');
      } else if (tabName === 'tabPollManagement') {
        router.push('/poll_management')
      }
    }
    
    return {
      activeTab,
      changeTab
    };
  }
}
</script>

<style scoped>
.tabs {
  display: flex;
  gap: 10px;
  background-color: #555555;
}

.tab-button {
  background: #C8CDE1;
  border: 2px solid #C8CDE1;
  color: #000000;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
}

.tab-button:hover, .tab-button:focus {
  background: #D8DDF1;
}
</style>