<template>
    <div class="login-prompt">
      <input id="inputUsername" type="text" v-model="adminId" placeholder="Username" />
      <input id="inputPassword" type="password" v-model="password" placeholder="Password" />
      <button @click="login">Login</button>
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: 'LoginPrompt',
    data() {
        return {
            adminId: '',
            password: ''
        };
    },
    methods: {
        login() {
            const loginData = {
                admin_id: this.adminId,
                password: this.password
            };
            axios.post('/php/admin_login.php', loginData, {
              withCredentials: true
            })
            .then(response => {
                if (response.data.status === 'success') {
                    this.$emit('admin-login-success');
                    console.log("login successful")
                } else {
                    this.errorMessage = response.data.message;
                    console.log("login unsuccessful")
                    console.log(response.data)
                }
            });
        }
    }
}
</script>

<style scoped>
  .login-prompt {
    width: 250px;
    padding: 20px;
    border: 1px solid #AAAAAA;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    display: flex;
    flex-direction: column;
    gap: 10px;
    background: #555555;
  }
  
  .login-prompt input {
    padding: 10px;
    border: 1px solid #CCCCCC;
    border-radius: 4px;
  }
  
  .login-prompt button {
    padding: 10px;
    background-color: #C8CDE1;
    border: none;
    border-radius: 4px;
    color: #000000;
    cursor: pointer;
  }
  
  .login-prompt button:hover {
    background-color: #D8DDF1;
  }
</style>
  