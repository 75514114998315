<template>
    <div class="card-container" v-if="comment" @click="emitViewReport">
        <div class="comment-content">"{{ comment.content }}"</div>
        <div class="report-reason">{{ report.reason_id }}</div>
        <div class="report-details">"{{ report.details }}"</div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    props: {
        report: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            comment: null
        }
    },

    mounted() {
        this.fetchPoll();
    },

    methods: {
        async fetchPoll() {
            try {
                const response = await axios.post('/php/poll_comment_get.php', {
                    comment_id: this.report.comment_id
                });

                if (response.data) {
                    this.comment = response.data;
                }
            } catch (error) {
                console.error('There was an error fetching the comment:', error);
            }
        },

        emitViewReport() {
            this.$emit('view-report', this.report.report_id);
        }
    }
}
</script>

<style scoped>
.card-container {
    display: flex;
    flex-direction: row;
    background-color: #C8CDE1;
    color: #000000;
    border: 2px solid #C8CDE1;
    border-radius: 10px;
    padding: 5px;
    margin:10px;
    cursor: pointer;
}

.card-container:hover {
    border: 2px solid #FFFFFF;
}

.comment-content {
    width: 20vw;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 20px;
    margin-right: 20px;
}

.report-reason {
    width: 10vw;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 20px;
    margin-right: 20px;
}

.report-details {
    width: 20vw;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 20px;
    margin-right: 20px;
}
</style>