<template>
    <div class="master-container" v-if="poll && user">

        <div class="header">
            {{ poll.content }}
        </div>

        <div class="poll-info">
            <br>
            <b>Poll Information:</b>
            <br>
            Posting User:  {{ user.display_name }} (@{{ poll.user_id }})
            <br>
            Options:
            <li v-for="option in pollOptions" :key="option.id">
                "{{ option.content }}"
            </li>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {

    data() {
        return {
            pollId: null,
            poll: null,
            user: null,
            pollOptions: [],
        }
    },

    mounted() {
        this.pollId = this.$route.params.pollId;
        this.fetchPoll();
    },

    methods: {

        async fetchPoll() {
            try {
                const response = await axios.post('/php/poll_get.php', {
                    poll_id: this.pollId
                });

                if (response.data) {
                    this.poll = response.data;
                    this.fetchUser();
                    this.fetchPollOptions();
                }
            } catch (error) {
                console.error('There was an error fetching the report:', error);
            }
        },

        async fetchUser() {
            try {
                const response = await axios.post('/php/user_get.php', {
                    user_id: this.poll.user_id
                });

                if (response.data) {
                    this.user = response.data;
                }
            } catch (error) {
                console.error('There was an error fetching the report:', error);
            }
        },

        async fetchPollOptions() {
            try {
                const response = await axios.post('/php/poll_options_get.php', {
                    poll_id: this.poll.poll_id
                });

                if (response.data) {
                    this.pollOptions = response.data;
                }
            } catch (error) {
                console.error('There was an error fetching the poll options:', error);
            }
        },
    }
}
</script>

<style scoped>
.master-container {
    display: flex;
    flex-direction: column;
    margin: 20px;
    text-align: left;
}
.header {
    color: #C8CDE1;
    font-size: 2rem;
}
.poll-info {
    color: #C8CDE1;
    font-size: 1.25rem;
}
</style>