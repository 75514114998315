<template>
    <div class="master-container" v-if="reportId && report && poll">
        <div class="header">
            <b>Poll Report: {{ reportId }}</b>
        </div>

        <div class="report-info">
            <br>
            <b>Report Information:</b>
            <br>
            Reason: {{ report.reason_id }}
            <br>
            Details:  "{{ report.details }}"
            <br>
            Reporting User:  {{ report.user_id }}
        </div>
        
        <div class="poll-info">
            <br>
            <b>Poll Information:</b>
            <br>
            Posting User:  {{ poll.user_id }}
            <br>
            Title:  "{{ poll.content }}"
            <br>
            Options:
            <li v-for="option in pollOptions" :key="option.id">
                "{{ option.content }}"
            </li>
        </div>

        <div class="report-options">
            <br>
            <b>Report Options:</b>
            <br>
            <div class="standard-button" @click="dismissReportNoAction">No Action, Dismiss Report</div>
            <div class="standard-button" @click="dismissReportRemovePoll">Delete Poll</div>
            <div class="standard-button" @click="dismissReportRemovePollBanUser">Delete Poll, Ban User</div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            reportId: null,
            report: null,
            poll: null,
            pollOptions: []
        }
    },

    mounted() {
        this.reportId = this.$route.params.reportId;
        this.fetchReport();
    },

    methods: {
        async fetchReport() {
            try {
                const response = await axios.post('/php/reported_poll_get.php', {
                    report_id: this.reportId
                });

                if (response.data) {
                    this.report = response.data;
                    this.fetchPoll();
                }
            } catch (error) {
                console.error('There was an error fetching the report:', error);
            }
        },

        async fetchPoll() {
            try {
                const response = await axios.post('/php/poll_get.php', {
                    poll_id: this.report.poll_id
                });

                if (response.data) {
                    this.poll = response.data;
                    this.fetchPollOptions();
                }
            } catch (error) {
                console.error('There was an error fetching the poll:', error);
            }
        },

        async fetchPollOptions() {
            try {
                const response = await axios.post('/php/poll_options_get.php', {
                    poll_id: this.report.poll_id
                });

                if (response.data) {
                    this.pollOptions = response.data;
                }
            } catch (error) {
                console.error('There was an error fetching the poll options:', error);
            }
        },

        async dismissReport() {
            try {
                const response = await axios.post('/php/reported_poll_delete_and_all_related.php', {
                    poll_id: this.report.poll_id
                });
                
                if (response.data.message) {
                    console.log(response.data.message);
                } else if (response.data.error) {
                    console.error(response.data.error);
                }
            } catch (error) {
                console.error('There was an error removing the reports:', error);
            }
        },

        async banUser() {
            try {
                const response = await axios.post('/php/user_ban.php', {
                    user_id: this.poll.user_id
                });
                
                if (response.data.message) {
                    console.log(response.data.message);
                } else if (response.data.error) {
                    console.error(response.data.error);
                }
            } catch (error) {
                console.error('There was an error banning the user:', error);
            }
        },

        async removePoll() {
            try {
                const response = await axios.post('/php/poll_remove.php', {
                    poll_id: this.report.poll_id
                });
                
                if (response.data.message) {
                    console.log(response.data.message);
                } else if (response.data.error) {
                    console.error(response.data.error);
                }
            } catch (error) {
                console.error('There was an error removing the poll:', error);
            }
        },

        dismissReportNoAction() {
            this.dismissReport();
            location.reload();
        },

        dismissReportRemovePoll() {
            this.removePoll();
            this.dismissReport();
            location.reload();
        },

        dismissReportRemovePollBanUser() {
            this.removePoll();
            this.dismissReport();
            this.banUser();
            location.reload();
        },

        emitViewReport() {
            this.$emit('view-report', this.report.report_id);
        }
    }
}
</script>

<style scoped>
.master-container {
    display: flex;
    flex-direction: column;
    margin: 20px;
    text-align: left;
}

.header {
    font-size: 1.5rem;
}

.report-info {
    font-size: 1.25rem;
}

.poll-info {
    font-size: 1.25rem;
}

.report-options {
    font-size: 1.25rem;
}

.standard-button {
    margin-top: 5px;
    margin-bottom: 5px;
}
</style>