<template>
    <div class="master-container">
        <ReportedPollsSideBar @update-limit="updateLimit" @update-offset="updateOffset"/>
        <div class="display-container">
            <router-view :reported-polls="reportedPolls" @go-to-report="goToReport"></router-view>
        </div>
    </div>
</template>


<script>
import axios from 'axios';

import ReportedPollsSideBar from '../components/reported_polls/ReportedPollsSideBar.vue';

export default {
    components: {
        ReportedPollsSideBar,
    },

    data() {
        return {
            reportedPolls: [],
            ascending: false,
            limit: null,
            offset: null
        };
    },

    mounted() {
        this.fetchReportedPolls();
    },

    methods: {
        toggleAscending() {
            this.ascending = !this.ascending;
        },
        updateLimit(newLimit) {
            this.limit = newLimit;
        },
        updateOffset(newOffset) {
            this.offset = newOffset;
        },
        fetchReportedPolls() {
            axios.post("/php/reported_polls_get_limit_offset.php", {
                ascending: this.ascending,
                limit: this.limit,
                offset: this.offset
            }).then(response => {
                this.reportedPolls = response.data;
            }).catch(error => {
                console.error("An error occurred while fetching data: ", error);
            });
        },
        
        goToReport(reportId) {
            this.$router.push({ name: 'ReportedPollPage', params: { reportId: reportId } });

        }
        
    },

    watch: {
        ascending: "fetchReportedPolls",
        limit: "fetchReportedPolls",
        offset: "fetchReportedPolls"
    }
}
</script>

<style scoped>
.master-container {
    display: flex;
    flex-direction: row;
}

</style>